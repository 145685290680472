import { parsePhoneNumber } from 'libphonenumber-js/max';

export const validationMixin = {
    name: 'validationMixin',

    methods: {
        isValidPhoneNumber(phone, isCheckPhoneLength = true) {
            /* phone must be in a string of this form "+country_codePhone_num" (without any space)
            i.e., +18143008714 here country_code: 1, phone_num: 8143008714 */
            if (!phone || phone === '') return false;
            if (phone[0] != '+') phone = '+' + phone;
            let res = true;
            try {
                parsePhoneNumber(phone).isValid();
            } catch (error) {
                res = false;
            }
            return (isCheckPhoneLength && phone.length === 10) || res;
        },
        isValidEmail(email){
            if(!email || email === '') return false;
            let validRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
            return email.match(validRegex);
        }
    }
  };